<template>
  <div class="blog-wrapper p-1">
    <MenuComponent />
    <b-card-text class="mb-3"> </b-card-text>
    <b-row>
      <b-col sm="1"></b-col>
      <b-col sm="10">
        <section class="app-ecommerce-details mb-3">
          <!-- Content -->
          <b-link href="/">
            <b-col cols="12" class="mt-3 text-center">
              <h2>Detail Produk</h2>
              <b-card-text class="mb-3">
                Dapatkan Produk Kami Terbaru Bimbel Terlengkap 2022
              </b-card-text>
            </b-col>
          </b-link>
          <b-card no-body>
            <b-card-body>
              <b-row class="my-2">
                <!-- Left: Product Image Container -->
                <b-col
                  cols="12"
                  md="5"
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    mb-2 mb-md-0
                  "
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <b-img
                      :src="getUrl + 'penyimpanan/product/' + dataProduct.photo"
                      alt="kosong"
                      class="product-img"
                      fluid
                      :style="{ width: '360px', height: '450px' }"
                    />
                  </div>
                </b-col>

                <!-- Right: Product Details -->
                <b-col cols="12" md="7">
                  <!-- Product Name -->
                  <h4>{{ dataProduct.title }}</h4>

                  <!-- Product Brand -->
                  <b-card-text class="item-company mb-0">
                    <span>Penulis: </span>
                    <b-link class="company-name">
                      {{ dataProduct.creator }}
                    </b-link>
                  </b-card-text>

                  <!-- Price And Ratings -->
                  <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                    <h4 class="item-price mr-1">
                      Rp. {{ formatPrice(dataProduct.price) }}
                    </h4>
                  </div>

                  <!-- Avability -->
                  <b-card-text
                    >Stock -
                    <span class="text-success">Tersedia</span></b-card-text
                  >

                  <!-- Product Description -->
                  <b-card-text class="space_good"
                    ><p style="text-align: justify">
                      {{ dataProduct.description }}
                    </p></b-card-text
                  >

                  <!-- Product Meta [Free shpping, EMI, etc.] -->
                  <ul class="product-features list-unstyled">
                    <li>
                      <feather-icon icon="BookOpenIcon" />
                      <span> Team Optimal</span>
                    </li>
                  </ul>

                  <hr />

                  <div class="text-center">
                    <b-link :href="dataProduct.link">
                      <b-button variant="primary" class="btn text-center">
                        <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                        <span>Check Out</span>
                      </b-button>
                    </b-link>
                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                    <b-link href="/">
                      <b-button variant="warning" class="btn" style="auto">
                        <feather-icon icon="HomeIcon" class="mr-50" />
                        <span>Kembali</span>
                      </b-button>
                    </b-link>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </section>
      </b-col>
    </b-row>
  </div>
</template>
  
  <script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
//   import { useEcommerceUi } from '../useEcommerce'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
  },
  data() {
    return {
      dataProduct: [],
      datapaket: [],
      selected: "left",
      selected2: "right",
      level: localStorage.getItem("Ulevel"),
      getUrl: "",
    };
  },
  async created() {
    await this.getData();
    this.getDatapaket();
    this.getUrl = process.env.VUE_APP_URL;
  },
  methods: {
    async getData() {
      const id = this.$route.params.id;
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "products/search/" + id)
        .then((res) => {
          this.dataProduct = res.data.data;
          // console.log(this.dataProduct);
        });
    },
    async getDatapaket() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "pakets/view_limit")
        .then((res) => {
          this.datapaket = res.data.data;
        });
    },
    formatPrice(value) {
      if (value != null) {
        const val = Number(value).toFixed(2).replace(/\.00$/, "");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
  },
};
</script>
  
  <style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

.space_good {
  white-space: pre-wrap;
}
</style>